import { Helmet } from "react-helmet-async";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
// @mui
import {
  Stack,
  Container,
  Typography,
  Button,
  Switch,
  Grid,
  IconButton,
  FormControlLabel,
  Modal,
  Box,
  InputAdornment,
  ImageList,
  Card,
  CardContent,
  CardActions,
  Chip
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { addBar, editBar, getBar } from "../../services/BarService";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import i18n from "../../i18n";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import config from "../../config";
import AccordionGroup from "./AccordionGroup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UploadFile from "../../components/Shared/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import PrinterTable from "../../components/Shared/PrinterTable";
import { deepClone } from "../../utils/deepClone";
// ----------------------------------------------------------------------
const CustomTimePicker = styled(DesktopTimePicker)`
  width: 100%;
  margin: 20px 0;
`;
const StatusSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
// ----------------------------------------------------------------------
class AddEditBar extends React.Component {
  constructor(props) {
    super(props);
    this.orderStatusOptions = config.orderStatusOptions;
    this.orderTypeOptions = config.orderTypeOptions;
    this.paymentTypeOptions = config.paymentTypeOptions;
    this.currencyTypeOptions = config.currencyTypeOptions;
    this.role = localStorage.getItem(config.roleKey);
    this.paymentAccessibleRoles = ["superadmin", "admin", "barowner"];
    this.Swal = require("sweetalert2");
    this.state = {

        /** bar form values */
        name: "",
        description: "",
        address: { addressLine1: "" },
        location: { type: "", coordinates: [0, 0] },
        type: { label: "", value: "" },
        ready_orders_completes_in: 0,
        status: "active",
        printers: [],
        assets: [],
        VAT:[],
        canPayLater:false,
        closesAt:null,
        hidden:false,
        isCurrentlyOpen:false,
        onlineClosesAt:'',
        onlineOpensAt:'',
        opensAt:'',
        payrexx_details:{instance:"",secret:""},
        printers:[],
        table_numbers:[],
        enable_table_service: false,
        force_input_table_number: false,
        enable_beeper: false,
        payment_gateway: '',
        currency: '',
        groups: [],
        twint_direct: {
          merchant_uuid: "",
          cashregister_id: "",
          certificate: null,
          certificate_password:""
        },

        /**UI handled Data */
        bars: [],
        errors: {},
        editMode: false,
        VAT: [],
        discardWarning: false,
        showDiscardModal: false,
        tab:'settings',
        showInstance: false,
        showSecret: false,
    };
    this.style = {
      modelClass: {
        position: "absolute",
        top: "10%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      },
      normalModalClass: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      },
    };
    this.getItemsData = this.getItemsData.bind(this);
    this.discardData = this.discardData.bind(this);
    this.handleFileSelection = this.handleFileSelection.bind(this);
    this.updatePrinterData = this.updatePrinterData.bind(this);
  }
  
  async componentDidMount() {
    if (this.props.params?.bar_id) {
      this.getBarData();
      this.setState({ editMode: true });
    }
  }
  
  async isFormValid() {
    let error = await this.validateFormField();
    if (Object?.keys(error)?.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async getBarData() {
    this.Swal.showLoading();
    let response = await getBar(this.props.params?.bar_id);
    if (!response) {
      this.Swal.close();
      alert("Something went wrong");
      return;
    }
    if (response.error) {
      alert(response.error);
      return;
    }
    // --------- VAT -------------
    let existingVAT = response?.bar?.VAT.map((x) => {
      return { label: String(x), value: x };
    }) || [];
    let defaultVAT = config.defaultVAT.map((x) => {
      return { label: String(x.value), value: x.value };
    });
    let totalVAT = [...existingVAT, ...defaultVAT];
    // remove duplicate value from totalVAT
    totalVAT = totalVAT.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.value === thing.value)
    );
    let existingTable = [];
    response?.bar?.table_numbers.map((x) => {
      if (x !== null) {
        existingTable.push({ label: String(x), value: String(x) });
      }
    });
    let default_table = config.defaultTable.map((x) => {
      return { label: String(x.value), value: x.value };
    });
    let tempData = [...existingTable, ...default_table];
    tempData = tempData?.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.value === thing.value)
    );
    this.setState({ VAT: totalVAT, table_numbers: tempData });
    let data = this.changeResponseKey(response?.bar?.groups);
    let tempGroupData = this.updatePath(data);

    let tempOrderType;
    this.orderTypeOptions?.map((el) => {
      if (el?.value === response?.bar?.type) {
        tempOrderType = { ...el };
      }
    });
    let tempGatewayData = this.paymentTypeOptions?.filter(
      (data) => data?.value === response?.bar?.payment_gateway
    )?.[0];
    let tempCurrencyData = this.currencyTypeOptions?.filter(
      (data) => data?.value === response?.bar?.currency
    )?.[0];
    this.setState({
        VAT:existingVAT,
        VATOptions:totalVAT,
        address:response?.bar?.address,
        assets:response?.bar?.assets,
        canPayLater:response?.bar?.canPayLater,
        closesAt:response?.bar?.closesAt,
        description:response?.bar?.description,
        hidden:response?.bar?.hidden,
        isCurrentlyOpen:response?.bar?.isCurrentlyOpen,
        location:response?.bar?.location,
        name:response?.bar?.name,
        onlineClosesAt:response?.bar?.onlineClosesAt,
        onlineOpensAt:response?.bar?.onlineOpensAt,
        opensAt:response?.bar?.opensAt,
        payrexx_details:response?.bar?.payrexx_details,
        printers:response?.bar?.printers,
        status:response?.bar?.status,
        table_numbers:existingTable,
        table_numbers_Options:tempData,
        enable_table_service: response?.bar?.enable_table_service,
        force_input_table_number: response?.bar?.force_input_table_number,
        enable_beeper: response?.bar?.enable_beeper,
        twint_direct:response?.bar?.twint_direct,
        _id:response?.bar?._id,
        payment_gateway: tempGatewayData,
        currency: tempCurrencyData,
        type: tempOrderType,
        ready_orders_completes_in: response?.bar?.ready_orders_completes_in || 0,
        groups: [...tempGroupData],
    });

    this.Swal.close();
  }

  changeResponseKey(data) {
    let keys = ["consumptions", "categories"];
    data?.map((el) => {
      Object.keys(el)?.map((key) => {
        if (keys.includes(key)) {
          el["children"] = el[key];
          delete el[key];
          this.changeResponseKey(el["children"]);
        }
      });
    });
    return data;
  }

  updatePath(data, path) {
    let temp = data?.map((el, i) => {
      const tempBlockData = JSON.parse(JSON.stringify(el));
      if (path) {
        tempBlockData.path = `${path}.${i}`;
      } else {
        tempBlockData.path = String(i);
      }
      if (el?.children?.length > 0) {
        tempBlockData.children = this.updatePath(
          tempBlockData?.children,
          tempBlockData.path
        );
      }
      return tempBlockData;
    });
    return temp;
  }

  async validateFormField(field) {
    let error = deepClone(this.state.errors);
    if (field == null || field === "name") {
      if (!this.state?.name || this.state?.name === "") {
        error.name = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }

    if (field == null || field === "description") {
      if (
        !this.state?.description ||
        this.state?.description === ""
      ) {
        error.description = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }

    if (field == null || field === "address") {
      if (
        !this.state?.address?.addressLine1 ||
        this.state?.address?.addressLine1 === ""
      ) {
        error.address = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }

    if (field == null || field === "latitude") {
      if (
        !this.state?.location?.coordinates?.[1] ||
        this.state?.location?.coordinates?.[1] === ""
      ) {
        error.latitude = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
      const reg = new RegExp(
        `^([+-])?(?:90(?:\\.0{1,6})?|((?:|[1-8])[0-9])(?:\\.[0-9]{1,6})?)$`
      );
      if (
        this.state?.location?.coordinates?.[1] &&
        !reg.test(this.state?.location?.coordinates?.[1])
      ) {
        error.latitude = i18n.t("enter_a_valid_latitude");
      }
    }

    if (field == null || field === "longitude") {
      if (
        !this.state?.location?.coordinates?.[0] ||
        this.state?.location?.coordinates?.[0] === ""
      ) {
        error.longitude = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
      const reg = new RegExp(
        "^([+-])?(?:180(?:\\.0{1,6})?|((?:|[1-9]|1[0-7])[0-9])(?:\\.[0-9]{1,6})?)$"
      );
      if (
        this.state?.location?.coordinates?.[0] &&
        !reg.test(this.state?.location?.coordinates?.[0])
      ) {
        error.longitude = i18n.t("enter_a_valid_longitude");
      }
    }
    if (field == null || field === "type") {
      if (
        !this.state?.type?.value ||
        this.state?.type?.value === ""
      ) {
        error.type = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }
    if (field == null || field === "payment_gateway") {
      if (
        !this.state?.payment_gateway?.label ||
        this.state?.payment_gateway?.label === ""
      ) {
        error.payment_gateway = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }
    if (
      this.state?.payment_gateway?.label &&
      (field == null || field === "instance")
    ) {
      if (
        !this.state?.payrexx_details?.instance ||
        this.state?.payrexx_details?.instance === ""
      ) {
        error.instance = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }
    if (
      this.state?.payment_gateway?.label &&
      (field == null || field === "secret")
    ) {
      if (
        !this.state?.payrexx_details?.secret ||
        this.state?.payrexx_details?.secret === ""
      ) {
        error.secret = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }
    if (field == null || field === "currency") {
      if (
        !this.state?.currency?.label ||
        this.state?.currency?.label === ""
      ) {
        error.currency = i18n.t("this_field_is_required");
      } else {
        delete error?.[field];
      }
    }
    this.setState({ errors: error });

    return error;
  }

  async addEditBarDetails() {
    if (!(await this.isFormValid())) {
      return;
    }

    let payload = {
      VAT:this.state?.VAT,
      table_numbers:this.state?.table_numbers,
      enable_table_service: this.state?.enable_table_service,
      force_input_table_number: this.state?.force_input_table_number,
      enable_beeper: this.state?.enable_beeper,
      address:this.state?.address,
      assets:this.state?.assets,
      canPayLater:this.state?.canPayLater,
      closesAt:this.state?.closesAt,
      description:this.state?.description,
      hidden:this.state?.hidden,
      isCurrentlyOpen:this.state?.isCurrentlyOpen,
      name:this.state?.name,
      onlineClosesAt:this.state?.onlineClosesAt,
      onlineOpensAt:this.state?.onlineOpensAt,
      opensAt:this.state?.opensAt,
      payrexx_details:this.state?.payrexx_details,
      printers:this.state?.printers,
      status:this.state?.status,
      twint_direct:this.state?.twint_direct,
      _id:this.state?._id,
      type: this.state?.type?.value,
      ready_orders_completes_in: this.state?.ready_orders_completes_in,
      location: {
        type: "Point",
        coordinates: [
          Number(this.state?.location?.coordinates?.[0]),
          Number(this.state?.location?.coordinates?.[1]),
        ],
      },
    };
    if (this.state?.payment_gateway) {
      payload.payment_gateway = this.state?.payment_gateway.value;
    }
    if (this.state?.currency) {
      payload.currency = this.state?.currency.value;
    }

    if (payload.VAT && payload.VAT.length > 0) {
      payload.VAT = payload.VAT.map((x) => {
        return x.value;
      });
    }
    if (payload.table_numbers && payload.table_numbers?.length > 0) {
      payload.table_numbers = payload.table_numbers.map((x) => {
        return String(x.value);
      });
    }
    let data = this.formatKeys(this.state?.groups);
    if (data?.length > 0) {
      payload.groups = [...data];
    }else{
      payload.groups = [];
    }

    if (this.props.params?.bar_id) {
      this.editBarData(payload);
    } else {
      this.addBarData(payload);
    }
  }
  async addBarData(payload) {
    this.Swal.showLoading();
    let response = await addBar(payload);
    if (response?.response?.data.error) {
      this.Swal.close();
      this.Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.response?.data.error_code
          ? i18n.t(response?.response?.data.error_code)
          : response?.response?.data.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    if (response) {
      this.Swal.close();
      this.Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        text: i18n.t("bar_created_successfully"),
        showConfirmButton: false,
        timer: 3000,
      });
      this.props.navigate(`/bars/${response?.bar?._id}`);
      window.location.reload();
    }
  }

  async editBarData(payload) {
    this.Swal.showLoading();
    let response = await editBar(payload);
    if (response?.response?.data.error) {
      this.Swal.close();
      this.Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.response?.data.error_code
          ? i18n.t(response?.response?.data.error_code)
          : response?.response?.data.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    if (response) {
      this.Swal.close();
      this.Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        text: i18n.t("bar_details_updated_successfully"),
        showConfirmButton: false,
        timer: 3000,
      });
      this.getBarData();
    }
  }

  goBack() {
    this.props.navigate("/bars");
  }
  formatKeys(data) {
    data?.map((el) => {
      if (el?.path?.split(".")?.length === 1) {
        el["categories"] = el["children"];
        delete el["children"];
        delete el["path"];
        this.formatKeys(el["categories"]);
      } else if (el?.path?.split(".")?.length === 2) {
        el["consumptions"] = el["children"];
        delete el["children"];
        delete el["path"];
        this.formatKeys(el["consumptions"]);
      }
      if (!el["createdAt"]) {
        delete el["_id"];
      }
      delete el["children"];
      delete el["path"];
    });
    return data;
  }
  getItemsData(data) {
    this.setState({
        groups: [...data],
    });
  }
  discardData(dataEl) {
    this.setState({
      discardWarning: dataEl,
    });
  }

  async handleFileSelection(action, key, data) {
    if (action === "add") {
      if (key === "certificate") {
        let error = deepClone(this.state?.errors);
        delete error?.["certificate"];
        let twint_direct = this.state.twint_direct || {};
        twint_direct['certificate'] = data;
        await this.setState({
            twint_direct,
            errors: error,
        });
      } else {
        /**data refers to imageIds from response */
        if (this.state?.assets?.length > 0) {
          let oldAssets = deepClone(this.state?.assets);
          let tempData = [...oldAssets, ...data];
          await this.setState({
              assets: [...tempData],
          });
        } else {
          let tempData = [...data];
          await this.setState({
              assets: [...tempData],
          });
        }
      }
    } else if (action === "delete") {
      let tempData = this.state?.assets;
      /**data refers to Index */
      delete tempData[data];
      let filteredData = tempData?.filter((data) => data !== null);
      await this.setState({
          assets: filteredData,
      });
    }
  }
  async updatePrinterData(printerData) {
    await this.setState({
        printers: printerData,
    });
  }

  editPageForm=()=>{
      return(
      <>
      <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <TextField
          required
          fullWidth
          id="name"
          label={i18n.t("name")}
          name="name"
          autoComplete="name"
          onChange={async (e) => {
            await this.setState({
              name: e.target.value
            });
            await this.validateFormField("name");
          }}
          error={this.state.errors?.name ? true : false}
          helperText={this.state.errors?.name}
          value={this.state?.name}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          required
          fullWidth
          id="description"
          label={i18n.t("description")}
          name="description"
          multiline
          rows={2}
          maxRows={4}
          autoComplete="description"
          onChange={async (e) => {
            await this.setState({
                description: e.target.value,
            });
            await this.validateFormField("description");
          }}
          error={this.state.errors?.description ? true : false}
          helperText={this.state.errors?.description}
          value={this.state?.description}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          required
          fullWidth
          id="address"
          label={i18n.t("address")}
          name="address"
          multiline
          rows={2}
          autoComplete="address"
          onChange={async (e) => {
            await this.setState({
                address: { addressLine1: e.target.value },
            });
            await this.validateFormField("address");
          }}
          error={this.state.errors?.address ? true : false}
          helperText={this.state.errors?.address}
          value={this.state?.address?.addressLine1}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Autocomplete
          multiple
          id="vat_rates"
          options={this.state?.VATOptions || []}
          value={this.state?.VAT || []}
          renderInput={(params) => (
            <TextField {...params} label={i18n.t("vat_rates")} />
          )}
          onChange={async(event, newValue) => {
            let existing_VAT_options = this.state.VATOptions || []; 
            await this.setState({
              VAT:newValue,
              VATOptions:[...new Set([...newValue, ...existing_VAT_options])],
            });
          }}
          filterOptions={(options, params) => {
            const { inputValue } = params;
            // check if input value is a number
            const isNumber = !isNaN(inputValue);
            if (!isNumber) {
              return options;
            }

            const filtered = options.filter((option) => {
              return option.label.includes(inputValue);
            });
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: Number(inputValue),
                label: `${inputValue}`,
              });
            }
            return filtered;
          }}
        />
      </Grid>
      
      <Grid item xs={12} md={12}>
        <Autocomplete
          multiple
          fullWidth
          options={this.state?.table_numbers_Options || []}
          value={this.state?.table_numbers || []}
          renderInput={(params) => (
            <TextField {...params} label={i18n.t("Table")} />
          )}
          onChange={async(event, newValue) => {
            let existing_table_numbers_options = this.state.table_numbers_Options || []; 
            await this.setState({
              table_numbers:newValue,
              table_numbers_Options:[...new Set([...newValue, ...existing_table_numbers_options])],
            });
          }}
          filterOptions={(options, params) => {
            const { inputValue } = params;

            const filtered = options?.filter((option) => {
              return option?.label?.includes(inputValue);
            });
            const isExisting = options?.some(
              (option) => inputValue === option?.label
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: inputValue,
                label: `${inputValue}`,
              });
            }
            return filtered;
          }}
        />
        <FormControlLabel
          control={
            <StatusSwitch
              onChange={async (e) => {
                await this.setState({
                  enable_table_service: e.target.checked,
                });
                if (!e.target.checked) {
                  await this.setState({
                    force_input_table_number: false,
                  });
                }
              }}
              checked={this.state?.enable_table_service}
              className="mt-1"
              style={{ cursor: "pointer" }}
            />
          }
          label={i18n.t("enable_table_service")}
        />
        <FormControlLabel
          control={
            <StatusSwitch
              onChange={async (e) => {
                await this.setState({
                  force_input_table_number: e.target.checked,
                });
              }}
              checked={this.state?.force_input_table_number}
              className="mt-1"
              style={{ cursor: "pointer" }}
              disabled={!this.state?.enable_table_service}
            />
          }
          label={i18n.t("force_input_table_number")}
        />
        <FormControlLabel
          control={
            <StatusSwitch
              onChange={async (e) => {
                await this.setState({
                  enable_beeper: e.target.checked,
                });
              }}
              checked={this.state?.enable_beeper}
              className="mt-1"
              style={{ cursor: "pointer" }}
            />
          }
          label={i18n.t("enable_beeper")}
        />
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          id="type"
          options={this.orderTypeOptions}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
            label={i18n.t('order_type')}
              required
              error={this.state.errors?.type ? true : false}
              helperText={this.state.errors?.type}
            />
          )}
          onChange={async(option, value) => {
            await this.setState({
                type: { label: value?.label, value: value?.value },
            });
            await this.validateFormField("type");
          }}
          value={this.state?.type?.label || ""}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          fullWidth
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
          id="ready_orders_completes_in"
          label={i18n.t("ready_orders_completes_in")}
          name="ready_orders_completes_in"
          onWheel={(event) => event.target.blur()}
          onChange={async (e) => {
            await this.setState({
              ready_orders_completes_in: Number(e.target.value),
            });
          }}
          value={this.state?.ready_orders_completes_in}
        />
        {/* <TextField
          id="ready_orders_completes_in"
          label={i18n.t("ready_orders_completes_in")}
          type="number"
          fullWidth
          InputProps={{
            inputProps: { min: 0 },
          }}
          value={this.state?.ready_orders_completes_in}
          onChange={async (e) => {
            await this.setState({
                ready_orders_completes_in: e.target.value,
            });
          }}
        /> */}
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CustomTimePicker
            label={i18n.t('opensAt')}
            onChange={async(newValue) => {
              await this.setState({
                  opensAt: newValue?._d,
              });
            }}
            ampm={false}
            value={this.state?.opensAt || null}
            sx={{ width: 220 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CustomTimePicker
            label={i18n.t('closesAt')}
            onChange={async(newValue) => {
              await this.setState({
                  closesAt: newValue?._d,
              });
            }}
            ampm={false}
            value={this.state?.closesAt || null}
            renderInput={(params) => <TextField {...params} />}
            sx={{ width: 220 }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CustomTimePicker
            label={i18n.t('onlineOpensAt')}
            onChange={async(newValue) => {
              await this.setState({
                  onlineOpensAt: newValue?._d,
              });
            }}
            ampm={false}
            value={this.state?.onlineOpensAt || null}
            renderInput={(params) => <TextField {...params} />}
            sx={{ width: 220 }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CustomTimePicker
            label={i18n.t('onlineClosesAt')}
            onChange={async(newValue) => {
              await this.setState({
                  onlineClosesAt: newValue?._d,
              });
            }}
            ampm={false}
            value={this.state?.onlineClosesAt || null}
            renderInput={(params) => <TextField {...params} />}
            sx={{ width: 220 }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} className="pt-0">
        <FormControlLabel
          control={
            <StatusSwitch
              label={
                this.state?.status === "active"
                  ? i18n.t("active")
                  : i18n.t("inactive")
              }
              onChange={async (e) => {
                await this.setState({
                    status: e.target.checked ? "active" : "inactive",
                });
              }}
              checked={
                this.state?.status === "active" ? true : false
              }
              className="mt-1"
              style={{ cursor: "pointer" }}
            />
          }
          label={i18n.t("status")}
        />
        <FormControlLabel
          control={
            <StatusSwitch
              onChange={async (e) => {
                await this.setState({
                    hidden: e.target.checked,
                });
              }}
              checked={this.state?.hidden}
              className="mt-1"
              style={{ cursor: "pointer" }}
            />
          }
          label={i18n.t("hidden")}
        />
        <FormControlLabel
          control={
            <StatusSwitch
              onChange={async (e) => {
                await this.setState({
                    canPayLater: e.target.checked,
                });
              }}
              checked={this.state?.canPayLater}
              className="mt-1"
              style={{ cursor: "pointer" }}
            />
          }
          label={i18n.t("can_pay_later")}
        />
      </Grid>
      </Grid>

      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" className="mb-0 mt-3" gutterBottom>
          {i18n.t("geoLocation")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          type="number"
          id="latitude"
          label={i18n.t("latitude")}
          name="latitude"
          autoComplete="latitude"
          onWheel={(event) => event.target.blur()}
          onChange={async (e) => {
            let location = this.state.location || {};
            location['coordinates'] = [
                                        this.state.location?.coordinates?.[0],
                                        e.target.value,
                                      ];
            await this.setState({
                location
            });
            await this.validateFormField("latitude");
          }}
          error={this.state.errors?.latitude ? true : false}
          helperText={this.state.errors?.latitude}
          value={this.state?.location?.coordinates?.[1] || ""}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          type="number"
          id="longitude"
          label={i18n.t("longitude")}
          name="longitude"
          autoComplete="longitude"
          onWheel={(event) => event.target.blur()}
          onChange={async (e) => {
            let location = this.state.location || {};
            location['coordinates'] = [ 
                                        e.target.value,
                                        this.state.location?.coordinates?.[1],
                                      ];
            await this.setState({
                location
            });
            await this.validateFormField("longitude");
          }}
          error={this.state.errors?.longitude ? true : false}
          helperText={this.state.errors?.longitude}
          value={this.state?.location?.coordinates?.[0] || ""}
        />
      </Grid>
      </Grid>

      {this.paymentAccessibleRoles.includes(this.role) && (
      <>
        <Grid item xs={12} className="mt-4">
          <Typography variant="h5">
            {i18n.t("payment_details")}
          </Typography>
        </Grid>
        <Grid container spacing={2} className="mb-2">
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              id="type"
              options={this.currencyTypeOptions}
              sx={{ width: "100%", marginTop: 2, marginBottom: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n.t("currency")}
                  required
                  error={this.state.errors?.currency ? true : false}
                  helperText={this.state.errors?.currency}
                />
              )}
              onChange={async(option, value) => {
                await this.setState({
                    currency: {
                      label: value?.label,
                      value: value?.value,
                    },
                });
                this.validateFormField("currency");
              }}
              value={this.state?.currency?.label || ""}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            style={{ textAlign: "start", margin: "0" }}
          >
            <Autocomplete
              disablePortal
              id="type"
              options={this.paymentTypeOptions}
              sx={{ width: "100%", marginTop: 2, marginBottom: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n.t("payment_gateway")}
                  required
                  error={
                    this.state.errors?.payment_gateway ? true : false
                  }
                  helperText={this.state.errors?.payment_gateway}
                />
              )}
              onChange={async(option, value) => {
                await this.setState({
                    payment_gateway: {
                      label: value?.label,
                      value: value?.value,
                  },
                });
                this.validateFormField("payment_gateway");
              }}
              value={this.state?.payment_gateway?.label || ""}
            />
          </Grid>
          {this.state?.payment_gateway?.label && (
            <>
              <Grid
                item
                xs={12}
                md={4}
                style={{ textAlign: "start", margin: "0" }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type={this.state.showInstance ? "text" : "password"}
                  id="instance"
                  label={i18n.t("instance")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            this.setState({
                              showInstance: !this.state.showInstance,
                            });
                          }}
                        >
                          {!this.state.showInstance ? (
                            <VisibilityOffIcon />
                          ) : (
                            <RemoveRedEyeIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="instance"
                  autoComplete="instance"
                  onChange={async (e) => {
                    let payrexx_details = this.state.payrexx_details || {};
                    payrexx_details['instance'] = e?.target?.value;
                    await this.setState({
                        payrexx_details
                    });
                    await this.validateFormField("instance");
                  }}
                  error={this.state.errors?.instance ? true : false}
                  helperText={this.state.errors?.instance}
                  value={
                    this.state?.payrexx_details?.instance || ""
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ textAlign: "start", margin: "0" }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type={this.state.showSecret ? "text" : "password"}
                  id="secret"
                  label={i18n.t("secret")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            this.setState({
                              showSecret: !this.state.showSecret,
                            });
                          }}
                        >
                          {!this.state.showSecret ? (
                            <VisibilityOffIcon />
                          ) : (
                            <RemoveRedEyeIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="secret"
                  autoComplete="secret"
                  onChange={async (e) => {
                    let payrexx_details = this.state.payrexx_details || {};
                    payrexx_details['secret'] = e?.target?.value;
                    await this.setState({
                        payrexx_details
                    });
                    await this.validateFormField("secret");
                  }}
                  error={this.state.errors?.secret ? true : false}
                  helperText={this.state.errors?.secret}
                  value={
                    this.state?.payrexx_details?.secret || ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <StatusSwitch
                      onChange={async (e) => {
                        let payrexx_details = this.state.payrexx_details || {};
                        payrexx_details['enable_local'] = e.target.checked;
                        await this.setState({
                            payrexx_details
                        });
                      }}
                      checked={this.state?.payrexx_details?.enable_local}
                      className="mt-1"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  label={i18n.t("enable_local")}
                />
                <FormControlLabel
                  control={
                    <StatusSwitch
                      onChange={async (e) => {
                        let payrexx_details = this.state.payrexx_details || {};
                        payrexx_details['enable_online'] = e.target.checked;
                        await this.setState({
                            payrexx_details
                        });
                      }}
                      checked={this.state?.payrexx_details?.enable_online}
                      className="mt-1"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  label={i18n.t("enable_online")}
                />
              </Grid>
            </>
          )}
        </Grid>
      </>
      )}
      <PrinterTable
      printers={this.state?.printers}
      updatePrinterData={this.updatePrinterData}
      />
      <Grid container spacing={2} className="mb-2 mt-4">
      <Grid item xs={10}>
        <Typography variant="h5">{i18n.t("bar_images")}</Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className="d-flex"
        direction="row"
        justifyContent="flex-end"
      >
        <UploadFile
          uploadType="barImages"
          multiple={true}
          fileType="image"
          handleFileSelection={this.handleFileSelection}
          label={i18n.t("upload_bar_images")}
        />
      </Grid>
      <Grid item xs={12}>
        {this.state?.assets?.length > 0 && (
          <ImageList
            sx={{ width: "100%", minHeight: 250 }}
            cols={3}
            rowHeight={164}
          >
            {this.state?.assets?.map((item, index) => (
              <Card>
                <CardActions style={{ position: "absolute", right: 0 }}>
                  <Button
                    size="small"
                    onClick={() => {
                      this.handleFileSelection("delete", "assets", index);
                    }}
                  >
                    <CloseIcon style={{ color: "grey" }} />
                  </Button>
                </CardActions>
                <CardContent style={{ padding: "0" }}>
                  <img
                    style={{ maxWidth: "350px", maxHeight: "350px" }}
                    src={`${config.baseUrl}/assets/${item}`}
                    alt="Bar"
                  />
                </CardContent>
              </Card>
            ))}
          </ImageList>
        )}
      </Grid>
      <Grid item xs={12} className="mt-4">
        <AccordionGroup
          getItemsData={this.getItemsData}
          data={this.state?.groups || []}
          VATOptions={this.state?.VAT}
          showDiscardWarning={this.discardData}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{i18n.t("twint_direct")}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="merchant_uuid"
          label={i18n.t("merchant_uuid")}
          name="merchant_uuid"
          autoComplete="merchant_uuid"
          onChange={async (e) => {
            let twint_direct = this.state.twint_direct || {};
            twint_direct['merchant_uuid'] = e?.target?.value;
            await this.setState({
                twint_direct
            });
          }}
          value={this.state?.twint_direct?.merchant_uuid}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="cashregister_id"
          label={i18n.t("cashregister_id")}
          name="cashregister_id"
          autoComplete="cashregister_id"
          onChange={async (e) => {
            let twint_direct = this.state.twint_direct || {};
            twint_direct['cashregister_id'] = e.target.value;
            await this.setState({
                twint_direct
            });       
          }}
          value={this.state?.twint_direct?.cashregister_id}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{ maxHeight: "4.5rem" }}>
        <UploadFile
          uploadType="certificate"
          handleFileSelection={this.handleFileSelection}
          label={i18n.t("upload_certificate")}
        />
        {this.state?.twint_direct?.certificate?.name && (
          <>
            <Chip
              label={this.state?.twint_direct?.certificate?.name}
              variant="outlined"
              sx={{ mt: 1 }}
              onDelete={async () => {
                let twint_direct = this.state.twint_direct || {};
                twint_direct['certificate'] = null;
                await this.setState({
                    twint_direct
                });                
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="certificate_password"
          label={i18n.t("certificate_password")}
          name="certificate_password"
          autoComplete="certificate_password"
          type={this.state.showCertificatePassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    this.setState({
                      showCertificatePassword:
                        !this.state.showCertificatePassword,
                    });
                  }}
                  edge="end"
                >
                  {this.state.showCertificatePassword ? (
                    <RemoveRedEyeIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={async (e) => {
            let twint_direct = this.state.twint_direct || {};
            twint_direct['certificate_password'] = e.target.value;
            await this.setState({
                twint_direct
            });    
          }}
          value={
            this.state?.twint_direct?.certificate_password || ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <StatusSwitch
              onChange={async (e) => {
                let twint_direct = this.state.twint_direct || {};
                twint_direct['enable_local'] = e.target.checked;
                await this.setState({
                    twint_direct
                });
              }}
              checked={this.state?.twint_direct?.enable_local}
              className="mt-1"
              style={{ cursor: "pointer" }}
            />
          }
          label={i18n.t("enable_local")}
        />
        <FormControlLabel
          control={
            <StatusSwitch
              onChange={async (e) => {
                let twint_direct = this.state.twint_direct || {};
                twint_direct['enable_online'] = e.target.checked;
                await this.setState({
                    twint_direct
                });
              }}
              checked={this.state?.twint_direct?.enable_online}
              className="mt-1"
              style={{ cursor: "pointer" }}
            />
          }
          label={i18n.t("enable_online")}
        />
      </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-2">
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" mb={5} spacing={2} justifyContent={"center"}>
            <Button
              variant="contained"
              onClick={async () => {
                await this.addEditBarDetails();
              }}
            >
              {i18n.t("save")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                if (this.state?.discardWarning) {
                  this.setState({
                    showDiscardModal: true,
                  });
                  return;
                }
                this.goBack();
              }}
            >
              {i18n.t("cancel")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      </>
      )
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            {this.state.editMode
              ? i18n.t("edit_bar")
              : i18n.t("add_bar") | config.APPLICATION_NAME}
          </title>
        </Helmet>

        <Container
          style={{
            maxWidth: "100%",
            background: "white",
            borderRadius: "10px",
            padding: "20px 40px",
          }}
        >
          <Stack direction="row" alignItems="center" mb={5}>
            <IconButton>
              <ArrowBackIcon
                onClick={() => {
                  if (this.state?.discardWarning) {
                    this.setState({
                      showDiscardModal: true,
                    });
                    return;
                  }
                  this.goBack();
                }}
                style={{ cursor: "pointer" }}
              />
            </IconButton>
            <Typography variant="h4" className="mb-0" gutterBottom>
              {this.state.editMode ? i18n.t("edit_bar") : i18n.t("add_bar")}
            </Typography>
          </Stack>
          {this.editPageForm()}
        </Container>
        <Modal
          open={this.state.showDiscardModal}
          onClose={async() => {
            let modal = this.state.modal || {};
            modal['showConsumptionsModal'] = true;
            await this.setState({
                  modal
            });  
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Box sx={this.style?.modelClass}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {i18n.t("discard_changes")}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Do u want to discard the changes and go back.
              </Typography>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Button
                  variant="contained"
                  style={{ padding: "0.5rem 3rem", marginTop: "20px" }}
                  onClick={async () => {
                    await this.addEditBarDetails();
                  }}
                >
                  {i18n.t("save_changes_and_go_back")}
                </Button>
                <Button
                  variant="contained"
                  style={{
                    padding: "0.5rem 3rem",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    this.goBack();
                  }}
                >
                  {i18n.t("discard_changes_and_go_back")}
                </Button>
              </div>
            </Box>
          </div>
        </Modal>
      </>
    );
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditBar {...props} navigate={navigate} params={params} />;
}
