import React from "react";
import { Grid, Button, Typography, IconButton, Divider, Chip, Stack } from "@mui/material";
import i18n from "../../i18n";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import DragNDropContainer from "./DragAndDropContainer";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GppBadIcon from "@mui/icons-material/GppBad";
class ConsumptionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropPosition: "",
    };
    this.styles = {
      tableHeaderStyles: {
        textAlign: "start",
        background: "#f0f0f0",
        padding: "8px",
        marginLeft: "15px",
        paddingLeft: "16px",
      },
      tableBodyStyles: {
        textAlign: "start",
        padding: "10px",
        cursor: "pointer",
      },
      highlight: {
        border: "1px solid #0085f2",
        padding: "0px 10px 0px 10px",
        borderRadius: "10px",
      },
    };
    this.onDropEventHandler = this.onDropEventHandler.bind(this);
    this.highlightDrop = this.highlightDrop.bind(this);
  }
  async componentDidMount() {}
  async onDropEventHandler(draggedItemData, destPath) {
    this.props.onDragEnd(draggedItemData, destPath);
  }
  async highlightDrop(path, position) {
    this.setState({
      dropPosition: path,
    });
  }
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Grid container spacing={2} className="mb-4 mt-2">
          <Grid item xs={8} style={{ textAlign: "start" }}>
            <h5
              style={{
                fontWeight: "bold",
                fontSize: "1.20rem",
                marginBottom: "0",
              }}
            >
              {i18n.t("consumptions")}
            </h5>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                this.props.onAction(
                  "updateIndex",
                  this.props.categoryIndex,
                  this.props.groupIndex
                );
              }}
            >
              {i18n.t("add_consumptions")}
            </Button>
          </Grid>
        </Grid>
        <Grid className="d-flex" style={this.styles.tableHeaderStyles}>
          <Grid item xs={2}>
            {i18n.t("name")}
          </Grid>
          <Grid
            item
            className="d-flex"
            direction="row"
            justifyContent="right"
            xs={1}
          >
            {i18n.t("price")}
          </Grid>
          <Grid
            item
            className="d-flex"
            direction="row"
            justifyContent="right"
            xs={1}
          >
            {i18n.t("VAT")}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {i18n.t("quickOrder")}
          </Grid>
          <Grid item xs={1} style={{ textAlign: "center" }}>
            {i18n.t("online_hidden")}
          </Grid>
          <Grid item xs={1} style={{ textAlign: "center" }}>
            {i18n.t("local_hidden")}
          </Grid>
          <Grid
            item
            className="d-flex"
            direction="row"
            justifyContent="flex-end"
            xs={2}
          >
            {i18n.t("actions")}
          </Grid>
        </Grid>
        <DragNDropContainer
          key="empty-div"
          onDrop={this.onDropEventHandler}
          path="0"
          containerType="droppable"
          isEmpty
          canDrag={true}
          dragType=""
          dropType={["consumption"]}
          padding="5px 0"
        >
          {this.props?.data?.children?.map((row, foodIndex) => (
            <>
              <DragNDropContainer
                path={row?.path}
                dragItem={{ element: row }}
                highlightDrop={this.highlightDrop}
                onDrop={this.onDropEventHandler}
                canDrag={true}
                dragType={"consumption"}
                dropType={["consumption"]}
              >
                <div
                  style={
                    this.state.dropPosition === row?.path
                      ? this.styles?.highlight
                      : {}
                  }
                >
                  <Grid
                    container
                    className="d-flex"
                    style={this.styles.tableBodyStyles}
                  >
                    <Grid item xs={2}>
                      <DragIndicatorIcon style={{ cursor: "pointer" }} />
                      {row["name"]}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="d-flex"
                      direction="row"
                      justifyContent="right"
                    >
                      {row["price"] ? row["price"] : ''}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="d-flex"
                      direction="row"
                      justifyContent="right"
                    >
                      {row["VAT"] ? row["VAT"] : ''}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <Chip
                        label={row["quickOrder"] === true ? i18n.t("yes") : i18n.t("no")}
                        color={row["quickOrder"] === true ? "success" : "error"}
                        style={{
                          borderRadius: 5,
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "center" }}>
                      <Chip
                        label={row?.online_hidden === true ? i18n.t("yes") : i18n.t("no")}
                        color={row?.online_hidden === true ? "success" : "error"}
                        style={{
                          borderRadius: 5,
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "center" }}>
                    <Chip
                        label={row?.local_hidden === true ? i18n.t("yes") : i18n.t("no")}
                        color={row?.local_hidden === true ? "success" : "error"}
                        style={{
                          borderRadius: 5,
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}
                      style={{ textAlign: "end" }}
                    >
                      <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <IconButton>
                          <EditIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.onAction(
                                "showEditConsumptionModal",
                                this.props.categoryIndex,
                                this.props.groupIndex,
                                foodIndex,
                                row
                              );
                            }}
                          />
                        </IconButton>
                        <IconButton color="error">
                          <DeleteOutlineIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.onAction(
                                "delete",
                                this.props.categoryIndex,
                                this.props.groupIndex,
                                foodIndex
                              );
                            }}
                          />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </div>
              </DragNDropContainer>
            </>
          ))}
        </DragNDropContainer>
      </DndProvider>
    );
  }
}
export default ConsumptionsTable;
