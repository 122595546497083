import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import InfoIcon from '@mui/icons-material/Info';
import TwintIcon from './../../assets/images/TwintLogo.png';
import PayrexxIcon from './../../assets/images/PayrexxLogo.png';

const icons_map = {
    "cash" : <LocalAtmIcon style={{fontSize: '60', color: 'green'}} />,
    "card" : <CreditCardIcon style={{fontSize: '60', color: 'gray'}} />,
    "online" : <PhoneIphoneIcon style={{fontSize: '60', color: '#2065D1'}} />,
    "twint" : <div style={{display: 'flex', justifyContent: 'center'}}><img src={TwintIcon} style={{width: 'auto', height: 60}} /></div>,
    "payrexx" : <div style={{display: 'flex', justifyContent: 'center'}}><img src={PayrexxIcon} style={{width: 'auto', height: 60}} /></div>,
    "info" : <InfoIcon style={{fontSize: '60', color: 'blue'}} />
}

class HorizontalWidget extends React.Component {
    render() {
        return (
                <Card style={{ padding: 10 }}>
                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent="center"
                                alignItems="center"
                                textAlign={'center'}
                            >
                                {icons_map[this.props?.icon] || icons_map["info"]}
                                {/* <Typography variant="body1" style={{fontWeight: 'bolder'}}>{this.props?.iconText}</Typography> */}
                            </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="h5" style={{fontWeight: 'bolder'}}>{this.props?.title}</Typography>
                            <Typography variant="body1">{this.props?.text}</Typography>
                        </Grid>
                    </Grid>
                </Card>
        );
    }
}

export default HorizontalWidget;
