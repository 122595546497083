import { Grid } from "@mui/material";
import React from "react";
import config from "../../config";
import HorizontalWidget from "../widgets/horizontalWidget";
import { fCurrency } from "../../utils/formatNumber";
import i18n from "../../i18n";

class Widgets extends React.Component {
     
    showWidgets(paymentTypeDetail, index) {
        if(paymentTypeDetail?.paymentType === 'cash_difference' && paymentTypeDetail?.total == 0 || paymentTypeDetail?.count == 0) {
            return null;
        }
        
        return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
                <HorizontalWidget
                    title={fCurrency(paymentTypeDetail.total, i18n.t('OPTIONS.i18n_number_format'), config.currencyCode)}
                    text={paymentTypeDetail?.paymentType !== 'cash_difference' && i18n.t("total_transactions") + " - " + paymentTypeDetail.count}
                    icon={paymentTypeDetail.paymentType}
                    iconText={i18n.t(paymentTypeDetail.paymentType)}
                />
            </Grid>
        );
    }
    
    render() {
        let paymentTypeDetails = this.props?.paymentTypeDetails;
        let payrexxStatusDetails = this.props?.payrexxStatusDetails;

        return (
            <div style={{marginBottom: 10, marginTop: 10}}>

                <Grid container spacing={2} style={{justifyContent: 'center'}}>
                    {(!paymentTypeDetails || !payrexxStatusDetails) ? (
                        <p>{i18n.t('loading')}</p>
                    ) : null}
                    {(paymentTypeDetails && paymentTypeDetails.length === 0) ? (
                        <p>{i18n.t('no_data_found')}</p>
                    ) : null}
                    {paymentTypeDetails?.map((paymentTypeDetail, index) => {
                        return ( 
                             this.showWidgets(paymentTypeDetail,index)
                        )
                    })}
                </Grid>
            </div>
        );
    }
}

export default Widgets;
